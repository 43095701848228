const resourcesQuery = (postsPerPage = Number, offset = Number, activeFilters) => (
    `query Resources {
        resources(
            where: {
                offsetPagination: {size: ${postsPerPage}, offset: ${offset}},
                activeFilters: ${activeFilters}
            }
        ) {
            edges {
                node {
                    title
                    resource {
                        excerpt
                        featuredImage {
                            sizes(size: LARGE)
                            sourceUrl(size: LARGE)
                            srcSet(size: LARGE)
                        }
                        video
                        resourceType
                        file {
                            title
                            mediaItemUrl
                        }
                        downloadButtonTitle
                        link {
                            url
                            title
                        }
                        hubspotFormId
                        hubspotFormRegionCode
                        hubspotFormPortalId
                        author {
                            ... on CisilionAuthor {
                                title
                                author {
                                    company
                                    linkedinProfile
                                    position
                                    featuredImage {
                                        sizes(size: MEDIUM)
                                        sourceUrl(size: MEDIUM)
                                        srcSet(size: MEDIUM)
                                    }
                                }
                            }
                        }
                    }
                }
            }
            pageInfo {
                total
            }
        }
    }`
);

export default resourcesQuery;
